.Home {
  width: 100%;
  font-size: 0.16rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}

.Home .HomeTop {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 1rem;
  width: 100%;
  color: #ffffff;
  background: #00D491;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.Home .HomeTopItem {
  padding-left: 0.2rem;
  display: -webkit-flex;
  display: flex;
  font-size: 0.18rem;
  -webkit-flex: 1 1;
  flex: 1 1;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.Home .HomeTitle {
  margin-bottom: 0.15rem;
}

.Home .HomeTotal {
  height: 0.4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.Home .Total {
  font-size: 0.36rem;
  font-weight: bold;
  margin-right: 0.1rem;
}

.Home .HomeOrder {
  height: 0.4rem;
  font-size: 0.4rem;
  font-weight: bold;
}

.Home .PointBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 0.6rem;
  width: 100%;
  color: #ffffff;
  background: #00D491;
  -webkit-align-items: center;
  align-items: center;
}

.Home .PointItem {
  display: -webkit-flex;
  display: flex;
  font-size: 0.14rem;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: baseline;
  align-items: baseline;
  -webkit-flex: 1 1;
  flex: 1 1;
  padding-left: 0.2rem;
}

.Home .Point {
  font-size: 0.35rem;
  font-weight: bold;
  margin-right: 0.1rem;
}

.Home .chart {
  width: 85%;
  margin: 0.4rem 0;
}

.Home .withdrawBtn {
  -webkit-flex: 1 1;
  flex: 1 1;
  height: 0.5rem;
  line-height: 0.5rem;
  background: #00D491;
  font-size: 0.2rem;
  border-radius: 0.1rem;
  text-align: center;
  color: #ffffff;
}

.Home .btnContain {
  width: 85%;
  margin-top: 0.2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.Home .historyBtn {
  -webkit-flex: 1 1;
  flex: 1 1;
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.2rem;
  color: #00D491;
  border: 1PX solid #00D491;
  border-radius: 0.1rem;
  text-align: center;
}

.Home .logoutBtn {
  position: absolute;
  bottom: 0.6rem;
  right: 0.2rem;
  width: 0.5rem;
  height: 0.5rem;
  color: #fff;
  font-size: 0.08rem;
  background-color: #00D491;
  box-shadow: 0.03rem 0.03rem 0.03rem #888888;
  border-radius: 0.3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.Home .logout {
  width: 0.26rem;
  height: 0.26rem;
}