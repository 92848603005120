.PayHistory {
  width: 100%;
  font-size: 0.16rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.PayHistory .noDataBox {
  -webkit-justify-content: center;
  justify-content: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0.1rem;
  color: #999999;
  font-size: 0.15rem;
}

.PayHistory .no_data {
  width: 1.67rem;
  height: 0.97rem;
  margin-top: 2rem;
}

.PayHistory .itemBox {
  padding: 0.1rem 0.2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}

.PayHistory .infoBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 1;
  flex: 1 1;
  font-size: 0.18rem;
}

.PayHistory .point {
  font-size: 0.15rem;
  margin-top: 0.05rem;
  color: #666666;
}

.PayHistory .time {
  font-size: 0.12rem;
  margin-top: 0.05rem;
  color: #666666;
}

.PayHistory .statusBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  font-size: 0.18rem;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.PayHistory .priceBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.PayHistory .relPrice {
  font-size: 0.2rem;
}

.PayHistory .pricee {
  font-size: 0.16rem;
  text-decoration: line-through;
  color: #999999;
}

.am-list-header {
  padding: 0;
}

.am-list-footer {
  padding: 0;
}