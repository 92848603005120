.Withdraw {
  width: 100%;
  font-size: 0.16rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}

.Withdraw .forss {
  width: 100%;
}

.Withdraw .submit {
  width: 85%;
  height: 0.5rem;
  line-height: 0.5rem;
  background: #00D491;
  font-size: 0.2rem;
  border-radius: 0.1rem;
  text-align: center;
  color: #ffffff;
  margin-top: 0.8rem;
}

input {
  text-align: right!important;
}