.ChooseLocate {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.ChooseLocate #container {
  width: 100%;
  height: 100%;
}

.ChooseLocate .addressInfo {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}

.ChooseLocate .infoBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  background-color: #ffffff;
  padding: 0.08rem 0.16rem;
  border-radius: 0.1rem;
  box-shadow: 0.02rem 0.02rem 0.01rem #00000033;
  -webkit-align-items: center;
  align-items: center;
}

.ChooseLocate .btn {
  margin-left: 0.2rem;
  background: #00D491;
  font-size: 0.18rem;
  border-radius: 0.1rem;
  text-align: center;
  color: #ffffff;
  width: 0.6rem;
  height: 0.3rem;
  line-height: 0.3rem;
}

body {
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
}