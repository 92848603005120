.Login {
  width: 100%;
  font-size: 0.16rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}

.Login .LogoImg {
  width: 0.9rem;
  height: 0.9rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.Login .LoginBtn {
  width: 3.15rem;
  height: 0.48rem;
  margin-top: 1.15rem;
  background: #00D491;
  border-radius: 0.1rem;
  font-size: 0.18rem;
  color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.Login .LoginPro {
  bottom: 0.3rem;
  position: absolute;
}

.Login .Pro {
  color: #333333;
  font-size: 0.26rem;
}

.Login .LoginProBtn {
  color: #007aff;
  font-size: 0.26rem;
}

.Login .inputBox {
  width: 3.15rem;
  height: 0.6rem;
  margin-bottom: 0.05rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  font-size: 0.16rem;
  color: #333;
}

.Login .icon {
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.1rem;
}

.Login .input {
  padding: 0;
  font-size: 0.15rem;
  -webkit-flex: 1 1;
  flex: 1 1;
  height: 0.6rem;
  background: #00000000;
  border: 0 solid #00000000;
  outline: none;
}

.Login .line {
  width: 3.15rem;
  height: 1PX;
  background-color: #d1d1d1;
  margin-bottom: 0.15rem;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999999;
  opacity: 1;
  font-size: 0.16rem;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999999;
  opacity: 1;
  font-size: 0.16rem;
}

input:-ms-input-placeholder {
  color: #333333;
  opacity: 1;
  font-size: 0.16rem;
}

input::-webkit-input-placeholder {
  color: #333333;
  opacity: 1;
  font-size: 0.16rem;
}